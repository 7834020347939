import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Grid, Button, CircularProgress, Box } from '@mui/material';
import { getSingleOrgSeasonApi } from '../../api/orgSeasonApi';
import { LinkTile } from "../../shared/linkTile";
import { useParams, useNavigate } from 'react-router-dom';
import { OrgSeason } from "../../api/models/orgSeason";
import { Team } from "../../api/models/team";
import { ErrorHeader } from "../../shared/errorHeader";
import { useApi } from "../../hooks/useApi";
import { groupBy } from "../../utils/common";
import { InfoBar } from "../../shared/infoBar";

export function  OrgSeasonPage(){

    const [orgSeason, setOrgSeason] = useState<OrgSeason | undefined>(undefined);
    const [divisions, setDivisions] = useState<Map<string, Team[]> | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);

    const navigate = useNavigate();
    const params = useParams();

    const getOrgSeasonApi = useApi(getSingleOrgSeasonApi);

    const { user, isAuthenticated } = useAuth0();

    const navigateUrl = orgSeason ? `/orgs/${orgSeason.organization.id}` : '';


    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getOrgSeasonApi.data) {
            setOrgSeason(getOrgSeasonApi.data);
            const teams = getOrgSeasonApi.data.teams;
                const divisionList = groupBy<string, Team>(teams, team => team.division);
                setDivisions(divisionList);

        }
    }, [getOrgSeasonApi.data]);

    useEffect(() => {
        if (getOrgSeasonApi.error) {
            setErrorMessage(getOrgSeasonApi.error);
        }
    }, [getOrgSeasonApi.error]);

    function initPage(){

        if (!params.orgSeasonId) {
            throw Error('OrgSeason Id is missing');
        }

        //const access = await getAccess(accessToken, params.orgId, "org");
        //setIsAdmin(access === "admin" || access === "superadmin");

        getOrgSeasonApi.request(params.orgSeasonId);
    }

    const adminPageHandler = () => {
        if (isAuthenticated && orgSeason) {
            navigate(`/orgseason/${orgSeason.id}/admin`);
        }
    };

    function navigateProfilePhotoPageHandler() {
        if (orgSeason) {
            navigate(`/orgseason/${orgSeason.id}/photo-capture`);
        }
    }

    function renderHeader() {
        return (
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                <Grid item><h1>{orgSeason!.name}</h1></Grid>
                {isAuthenticated && user  && <Grid item> <Button color="primary"
                    variant="contained"
                    onClick={e => adminPageHandler()} >
                    Admin
                </Button></Grid>}
                <Grid item>
                    <Button color="primary"
                        variant="contained"
                        onClick={e => navigateProfilePhotoPageHandler()} >
                        Update Profile Photos
                    </Button>
                </Grid>

            </Grid>
        );
    }

    function renderTeams() {
        return (
            <Box>
                {orgSeason && divisions && Array.from(divisions.keys()).map((division) =>
                    <Grid item container direction='column'>
                        <Grid item><h3>{division}</h3></Grid>

                        <Grid item container>

                            {divisions.get(division)?.map(team =>
                                <Grid item>
                                    <LinkTile firstName={team.name}
                                        key={team.id}
                                        linkUrl={`/orgSeason/${orgSeason?.id}/team/${team.id}`}
                                        imageUrl={orgSeason.logoLink} />
                                </Grid>
                            )}

                        </Grid>
                    </Grid>
                )}
            </Box>
        );
    }

    const mainContent = () => {

        return (
            <Box>
                {renderHeader()}
                {orgSeason && divisions && renderTeams()}
            </Box>
        );
    }

    return (<Box>
        <InfoBar  navigateUrl={navigateUrl}  />
        
        <ErrorHeader errorMessage={errorMessage} />

        {getOrgSeasonApi.loading && <CircularProgress />}
        {orgSeason && mainContent()}
    </Box>
    );

}