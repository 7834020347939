import axios from 'axios';
import { AddImageCommand, FileDataAsString, IdResponse, ImportCommand, ImportResult, SetIsActiveCommand, UpdateNameCommand } from './models/common';
import { ChangeDivisionCommand, CopyMemberDataCommand, CopyMemberResult, CreateOrgSeasonCommand, CreateSponsorCommand, OrgSeason } from './models/orgSeason';
import { CreateTeamCommand } from './models/team';
import { GameCommand } from './models/game';

export async function addOrgSeasonApi(bearerToken: string, createOrgSeason: CreateOrgSeasonCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason`;
  await axios.post(baseUrl + endpoint, createOrgSeason, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function importMembersApi(bearerToken: string, orgSeasonId: string, importData: ImportCommand): Promise<ImportResult> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/import`;
  const response = await axios.post(baseUrl + endpoint, importData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as ImportResult;
}

export async function exportMembersApi(bearerToken: string, orgSeasonId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/export`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function getSampleImportFileApi(bearerToken: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/document/sample-import-file`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function updateOrgSeasonLogoApi(bearerToken: string, orgSeasonId: string, imageData: AddImageCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/logo`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateOrgSeasonNameApi(bearerToken: string, orgSeasonId: string, nameCommand: UpdateNameCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/name`;
  await axios.put(baseUrl + endpoint, nameCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getSingleOrgSeasonApi(bearerToken: string, orgSeasonId: string): Promise<OrgSeason> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}`;

  const headers: Record<string, string> = {};

  if (bearerToken) {
    headers['Authorization'] = `Bearer ${bearerToken}`;
  }


  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers });
  return response.data as OrgSeason;
}

export async function deleteSingleOrgSeasonApi(bearerToken: string, orgSeasonId: string): Promise<OrgSeason> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}`;
  const response = await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as OrgSeason;
}

export async function addSponsorApi(bearerToken: string, orgSeasonId: string, createSponsor: CreateSponsorCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/sponsor`;
  await axios.post(baseUrl + endpoint, createSponsor, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function setSponsorIsActiveApi(bearerToken: string, orgSeasonId: string, sponsorId: string, isActiveCommand: SetIsActiveCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/sponsor/${sponsorId}/isActive`;
  await axios.put(baseUrl + endpoint, isActiveCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateSponsorApi(bearerToken: string, orgSeasonId: string, sponsorId: string, createSponsor: CreateSponsorCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/sponsor/${sponsorId}`;
  await axios.put(baseUrl + endpoint, createSponsor, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function deleteSponsorApi(bearerToken: string, orgSeasonId: string, sponsorId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/sponsor/${sponsorId}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function changeDivisionApi(bearerToken: string, orgSeasonId: string, divisionCommand: ChangeDivisionCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/division`;
  await axios.post(baseUrl + endpoint, divisionCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function addTeamApi(bearerToken: string, orgSeasonId: string, createTeam: CreateTeamCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/team`;
  await axios.post(baseUrl + endpoint, createTeam, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getTeam(bearerToken: string, orgSeasonId: string, teamId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/team/${teamId}`;

  const headers: Record<string, string> = {};

  if (bearerToken) {
    headers['Authorization'] = `Bearer ${bearerToken}`;
  }

  await axios.get(baseUrl + endpoint, { timeout: 60000, headers });
}

export async function updateTeamName(bearerToken: string, orgSeasonId: string, teamId: string, nameCommand: UpdateNameCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/team/${teamId}`;
  await axios.put(baseUrl + endpoint, nameCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function deleteTeamApi(bearerToken: string, orgSeasonId: string, teamId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/team/${teamId}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function generateRoster(bearerToken: string, teamId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/roster/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateProgram(bearerToken: string, teamId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/program/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function viewRoster(bearerToken: string, teamId: string, orderBy: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/roster/view?orderby=${orderBy}`;

  const headers: Record<string, string> = {};

  if (bearerToken) {
    headers['Authorization'] = `Bearer ${bearerToken}`;
  }

  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers});
  return response.data as FileDataAsString;
}

export async function generateBinderLabels(bearerToken: string, teamId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/binder-sheet/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateGameSheet(bearerToken: string, teamId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/game-sheet/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function viewBinderLabels(bearerToken: string, teamId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/binder-sheet/view`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function getLabels(bearerToken: string, teamId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/labels`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function viewTeamTradingCards(bearerToken: string, teamId: string, layout: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/trading-cards/${layout}/view`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 180000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateTeamTradingCards(bearerToken: string, teamId: string, layout: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/trading-cards/${layout}/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 180000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function getProfileZips(bearerToken: string, orgSeasonId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/images/profile`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 180000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function getTeamProfileZips(bearerToken: string, teamId: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamId}/images/profile`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 180000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function copyMemberDataApi(bearerToken: string, orgSeasonId: string, copyCommand: CopyMemberDataCommand): Promise<CopyMemberResult> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/orgSeason/${orgSeasonId}/copy`;
  const response = await axios.post(baseUrl + endpoint, copyCommand, { timeout: 120000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as CopyMemberResult;
}

export async function addGameApi(bearerToken: string, teamGuid: string, addGameCommand:GameCommand): Promise<IdResponse> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/team/${teamGuid}/game`;
  const response = await axios.post(baseUrl + endpoint,addGameCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as IdResponse;
}








