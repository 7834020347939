import { Box, Grid } from "@mui/material";
import { LoginHeader } from "./loginHeader";
import { BasicMenu, BasicMenuItem } from "./basicMenu";
import { BasicBackButton } from "./basicBackButton";

export interface InfoBarProps {
    navigateUrl?: string,
    menuItems?:BasicMenuItem[]
}

export function InfoBar(props: InfoBarProps) {

    return (
        <Box sx={{ mt: '3px' }}>
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
                {props.navigateUrl && <BasicBackButton labelText='' navigateUrl={`${props.navigateUrl}`} />}
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    {props.menuItems && <Grid item>
                        <BasicMenu items={props.menuItems} />
                    </Grid>}
                    <Grid item>
                        <LoginHeader />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        </Box>
    );

}

