import React, { useEffect, useState } from "react";
import { Grid, Button, CircularProgress, Box } from '@mui/material';
import {
    getSingleOrgSeasonApi, viewTeamTradingCards, generateTeamTradingCards, getLabels,
    generateRoster, viewRoster, getProfileZips, getTeamProfileZips,
    generateBinderLabels,
    viewBinderLabels,
    generateProgram,
    generateGameSheet
} from '../../api/orgSeasonApi';
import { LinkTile } from "../../shared/linkTile";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useNavigate } from 'react-router-dom';
import { OrgSeason } from "../../api/models/orgSeason";
import { downloadBase64AsFile } from "../../utils/binaryHelper";
import { ErrorHeader } from "../../shared/errorHeader";
import { getAccess } from "../../api/userApi";
import { BasicSplitButton } from "../../shared/basicSplitButton";
import { OrgHeader } from "../../shared/orgHeader";
import { Team } from "../../api/models/team";
import { TeamMenu } from "./teamMenu";
import { BasicMenu } from "../../shared/basicMenu";
import { BasicBackButton } from "../../shared/basicBackButton";
import { useApi } from "../../hooks/useApi";
import { LoginHeader } from "../../shared/loginHeader";
import { InfoBar } from "../../shared/infoBar";


const splitButtonItems = [{ id: 'name', title: 'View Roster By Name' },
{ id: 'number', title: 'View Roster By Number' }];



export const TeamPage: React.FC = (): JSX.Element => {

    const [orgSeason, setOrgSeason] = useState<OrgSeason | null>(null);
    const [team, setTeam] = useState<Team | null>(null);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [accessLevel, setAccessLevel] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const params = useParams();
    const navigate = useNavigate();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const getOrgSeasonRequest = useApi(getSingleOrgSeasonApi);
    const generateRosterRequest = useApi(generateRoster);
    const viewRosterRequest = useApi(viewRoster);
    const getLabelsRequest = useApi(getLabels);
    const viewTeamTradingCardsRequest = useApi(viewTeamTradingCards);
    const generateTeamTradingCardsRequest = useApi(generateTeamTradingCards);

     const navigateUrl = orgSeason ? `/orgseason/${orgSeason.id}` : '';

    useEffect(() => {

        initPage();
    }, []);

    useEffect(() => {
        if (getOrgSeasonRequest.data) {

        }
    }, [getOrgSeasonRequest.data]);

    useEffect(() => {
        if (generateRosterRequest.data) {

        }
    }, [generateRosterRequest.data]);

    useEffect(() => {
        if (viewRosterRequest.data) {

        }
    }, [viewRosterRequest.data]);

    useEffect(() => {
        if (getLabelsRequest.data) {

        }
    }, [getLabelsRequest.data]);

    useEffect(() => {
        if (viewTeamTradingCardsRequest.data) {

        }
    }, [viewTeamTradingCardsRequest.data]);

    useEffect(() => {
        if (generateTeamTradingCardsRequest.data) {

        }
    }, [viewRosterRequest.data]);

    const menuItems = [
        { title: 'Add Member', onClick: addMemberHandler },
        { title: 'Generate Roster', onClick: generateRosterHandler },
        { title: 'Generate Cards', onClick: generate9UPCardsHandler },
        { title: 'Generate Cards - 1 UP', onClick: generate1UPCardsHandler },
        { title: 'Generate Binder Labels', onClick: generateBinderLabelsHandler },
        { title: 'Generate Name Labels', onClick: generateLabelsHandler },
        { title: 'Generate Program', onClick: generateProgramHandler },

        { title: 'Manage Games', onClick: gamePageHandler },

        
        { title: 'Download Cards', onClick: download9UPCardsHandler },
        { title: 'Download Cards - 1 UP', onClick: download1UPCardsHandler },
        { title: 'Download Binder Labels', onClick: downloadBinderLabelsHandler },
        { title: 'Download Profiles', onClick: downloadProfileHandler },
        { title: 'Download Game Sheet', onClick: generateGameSheetHandler },

    ];

    async function initPage() {

        try {
            setIsLoading(true);
            setErrorMessage('');

            if (!params.orgSeasonId) {
                throw Error('OrgSeasonsId is missing');
            }

            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });

            const access = await getAccess(accessToken, params.orgSeasonId, "orgSeason");
            setAccessLevel(access);
            setIsAdmin(access === "admin" || access === "superadmin");

            if (!access) {
                throw Error('You dont have permission to access this data');
            }

            //todo: have logic / endpoint to just get 1 team
            const orgSeason = await getSingleOrgSeasonApi(accessToken, params.orgSeasonId);
            setOrgSeason(orgSeason);
            const selectedTeam = orgSeason.teams.find(team => team.id === params.teamId);
            if (selectedTeam) {
                const sortedMembers = selectedTeam.members.sort((a, b) => a.lastName.localeCompare(b.lastName));
                selectedTeam.members = sortedMembers;
                setTeam(selectedTeam);
            }

            setIsLoading(false);

        } catch (error: any) {
            displayError(error);
            setIsLoading(false);
        }
    }

    function displayError(error: any) {
        if (error.response?.data) {
            setErrorMessage(error.response.data.message);
        }
        else {
            setErrorMessage(error.message);
        }
    }

    async function generateRosterHandler() {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const rosterResponse = await generateRoster(accessToken, team!.id);
            downloadBase64AsFile(rosterResponse.data, rosterResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }

    };

    async function generateProgramHandler() {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const programResponse = await generateProgram(accessToken, team!.id);
            downloadBase64AsFile(programResponse.data, programResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }

    };

    async function downloadRosterHandler(orderBy: string) {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const rosterResponse = await viewRoster(accessToken, team!.id, orderBy);
            downloadBase64AsFile(rosterResponse.data, rosterResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
    };

    async function generate9UPCardsHandler() {
        await generateCardsHandler('9UP');
    }

    async function generate1UPCardsHandler() {
        await generateCardsHandler('1UP');
    }

    async function generateCardsHandler(layout: string) {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
            const cardResponse = await generateTeamTradingCards(accessToken, team!.id, layout);
            downloadBase64AsFile(cardResponse.data, cardResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
    };

    async function download9UPCardsHandler() {
        await downloadCardsHandler('9UP');
    }

    async function download1UPCardsHandler() {
        await downloadCardsHandler('1UP');
    }

    async function downloadCardsHandler(layout: string) {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}` });
            const cardResponse = await viewTeamTradingCards(accessToken, team!.id, layout);
            downloadBase64AsFile(cardResponse.data, cardResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
    };

    async function generateBinderLabelsHandler() {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const labelResponse = await generateBinderLabels(accessToken, team!.id);
            downloadBase64AsFile(labelResponse.data, labelResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
    };

    async function downloadBinderLabelsHandler() {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const rosterResponse = await viewBinderLabels(accessToken, team!.id);
            downloadBase64AsFile(rosterResponse.data, rosterResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
    };

    async function generateLabelsHandler() {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const labelResponse = await getLabels(accessToken, team!.id);
            downloadBase64AsFile(labelResponse.data, labelResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
    };

    async function downloadProfileHandler() {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const labelResponse = await getTeamProfileZips(accessToken, team!.id);
            downloadBase64AsFile(labelResponse.data, labelResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
    };

    async function generateGameSheetHandler() {
        setIsProcessing(true);
        try {
            let accessToken = await getAccessTokenSilently({ audience: `${process.env.REACT_APP_AUTH0_AUDIENCE}`, });
            const rosterResponse = await generateGameSheet(accessToken, team!.id);
            downloadBase64AsFile(rosterResponse.data, rosterResponse.name);
            setIsProcessing(false);
        }
        catch (error: any) {
            displayError(error);
            setIsProcessing(false);
        }
       
    };

    function addMemberHandler() {
        if (orgSeason && team) {
            navigate(`/orgseason/${orgSeason.id}/team/${team.id}/add`);
        }
    };

    async function gamePageHandler() {
        if (orgSeason && team) {
            navigate(`/orgseason/${orgSeason.id}/team/${team.id}/game`);
        }
    }

    function generalButtons() {
        return (
            <Grid container spacing={2}>
                <Grid item>
                    <BasicSplitButton items={splitButtonItems} onClick={downloadRosterHandler} />
                </Grid>
            </Grid>
        );
    }

    function mainContent() {

        return (
            <Box>
                <h1>{team!.name}</h1>
                {isProcessing && <CircularProgress />}

                <br />
                {!isProcessing && generalButtons()}

                <h3>Athletes ({team?.members.length})</h3>
                <Grid container>
                    {team && team.members.map(item =>
                        <Grid item>
                            <LinkTile
                                key={item.id}
                                firstName={item.firstName}
                                lastName={item.lastName}
                                identifier={item.jerseyNumber?.toString() ?? ''}
                                linkUrl={isAuthenticated ? `/orgSeason/${params.orgSeasonId}/member/${item.id}` : ''}
                                imageUrl={item.profileLink} />
                        </Grid>
                    )}
                </Grid>

                <h3>Leaders ({team?.leaders.length})</h3>
                <Grid container>
                    {team && team.leaders.map(item =>
                        <Grid item>
                            <LinkTile
                                key={item.id}
                                firstName={item.firstName}
                                lastName={item.lastName}
                                identifier={item.identifier}
                                linkUrl={isAuthenticated ? `/orgSeason/${params.orgSeasonId}/member/${item.id}` : ''}
                                imageUrl={item.profileLink} />
                        </Grid>
                    )}
                </Grid>
            </Box>
        );
    }

    return (<Box>
        <InfoBar menuItems={menuItems} navigateUrl={navigateUrl}  />
       
        <ErrorHeader errorMessage={errorMessage} />

        {isLoading && <CircularProgress />}
        {!isLoading && team && mainContent()}
    </Box>
    );

}