import axios from 'axios';
import { AddImageCommand, ChangeTeamCommand, FileDataAsString, IdResponse, ImageResponse,  UpdateJerseyNumberCommand, UpdateMemberNameCommand, UpdatePositionCommand, UpdateTitleCommand } from './models/common';
import { AddDocumentCommand, CreateMemberCommand, Member, MemberDocument, UpdateMemberMetadataCommand, UpdatePersonalPageCommand } from './models/member';

export async function createMember(bearerToken: string, createMember: CreateMemberCommand): Promise<IdResponse> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member`;
  const response = await axios.post(baseUrl + endpoint, createMember, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as IdResponse;
}

export async function updateMemberProfileImage(bearerToken: string, memberGuid: string, imageData: AddImageCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/profile`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberActionImage(bearerToken: string, memberGuid: string, imageData: AddImageCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/action`;
  await axios.put(baseUrl + endpoint, imageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberName(bearerToken: string, memberGuid: string, memberName: UpdateMemberNameCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/name`;
  await axios.put(baseUrl + endpoint, memberName, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberJerseyNumber(bearerToken: string, memberGuid: string, command: UpdateJerseyNumberCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/jersey-number`;
  await axios.put(baseUrl + endpoint, command, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberPosition(bearerToken: string, memberGuid: string, command: UpdatePositionCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/position`;
  await axios.put(baseUrl + endpoint, command, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberTitle(bearerToken: string, memberGuid: string, command: UpdateTitleCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/title`;
  await axios.put(baseUrl + endpoint, command, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberMetaData(bearerToken: string, memberGuid: string, metaData: UpdateMemberMetadataCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/metadata`;
  await axios.put(baseUrl + endpoint, metaData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updatePersonalPage(bearerToken: string, memberGuid: string, personalPageData:UpdatePersonalPageCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/personalPage`;
  await axios.put(baseUrl + endpoint, personalPageData, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}







export async function updateMemberAssignTeam(bearerToken: string, memberGuid: string, changeTeamCommand: ChangeTeamCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/assign-team`;
  await axios.put(baseUrl + endpoint, changeTeamCommand, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function updateMemberRemoveTeam(bearerToken: string, memberGuid: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/remove-team`;
  await axios.put(baseUrl + endpoint, null, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function deleteMember(bearerToken: string, memberGuid: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getMember(bearerToken: string, memberGuid: string): Promise<Member> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as Member;
}

export async function getMemberProfileImage(bearerToken: string, memberGuid: string): Promise<ImageResponse> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/profile`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as ImageResponse;
}

export async function getMemberActionImage(bearerToken: string, memberGuid: string): Promise<ImageResponse> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/action`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as ImageResponse;
}

export async function addMemberDocumentApi(bearerToken: string, memberGuid: string, docRequest: AddDocumentCommand): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/document`;
  await axios.post(baseUrl + endpoint, docRequest, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function getMemberDocumentApi(bearerToken: string, memberGuid: string, documentId: string): Promise<MemberDocument> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/document/${documentId}`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as MemberDocument;
}

export async function deleteMemberDocumentApi(bearerToken: string, memberGuid: string, documentId: string): Promise<void> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/document/${documentId}`;
  await axios.delete(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
}

export async function viewMemberTradingCards(bearerToken: string, memberGuid: string, layout: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/trading-cards/${layout}/view`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateMemberTradingCards(bearerToken: string, memberGuid: string, layout: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/trading-cards/${layout}/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function generateBinderSheet(bearerToken: string, memberGuid: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/binder-sheet/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000, headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function getMemberQRLabel(bearerToken: string, memberGuid: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/athlete/${memberGuid}/qrcode/view`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000,headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

export async function getMemberQRSheet(bearerToken: string, memberGuid: string): Promise<FileDataAsString> {
  const baseUrl = process.env.REACT_APP_API_URL;
  const endpoint = `/api/v1/member/${memberGuid}/qr-sheet/generate`;
  const response = await axios.get(baseUrl + endpoint, { timeout: 60000,headers: { 'Authorization': `Bearer ${bearerToken}` } });
  return response.data as FileDataAsString;
}

