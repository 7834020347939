import { AppBar, Toolbar, Box, Grid, Typography } from '@mui/material';
import { AuthenticationButton } from '../auth/authenticationButton';




export function LoginHeader() {

    return (
        <>
       
        <Box>
        <AuthenticationButton />
        </Box>
    </>
    );
}