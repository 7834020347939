import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from '@mui/material';
import { getSingleOrganizationApi } from '../../api/organizationApi';
import { LinkTile } from "../../shared/linkTile";
import { Organization } from "../../api/models/organization";
import { useParams, useNavigate } from 'react-router-dom';
import { ErrorHeader } from "../../shared/errorHeader";
import { useApi } from "../../hooks/useApi";
import { InfoBar } from "../../shared/infoBar";
import { useAuth0 } from "@auth0/auth0-react";

export function OrganizationPage() {

    const [organization, setOrganization] = useState<Organization | undefined>(undefined);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const { user, isAuthenticated} = useAuth0();

    const params = useParams();
    const navigate = useNavigate();

    const getOrgApi = useApi(getSingleOrganizationApi);

   
    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getOrgApi.data) {
            setOrganization(getOrgApi.data);
        }
    }, [getOrgApi.data]);

    useEffect(() => {
        if (getOrgApi.error) {
            setErrorMessage(getOrgApi.error);
        }
    }, [getOrgApi.error]);

    function initPage(){

        if (!params.orgId) {
            throw Error('Organization Id is missing');
        }

        //const access = await getAccess(accessToken, params.orgId, "org");
        //setIsAdmin(access === "admin" || access === "superadmin");

        getOrgApi.request(params.orgId);
    }

    function adminPageHandler() {
        if (isAuthenticated && user && organization) {
            navigate(`/orgs/${organization.id}/admin`);
        }
    }

    function renderHeader() {
        return (
            <Grid container spacing={2} justifyContent="flex-start" alignItems="center" >
                <Grid item><h1>{organization!.name}</h1></Grid>
                {isAuthenticated && user && <Grid item> <Button color="primary"
                    variant="contained"
                    onClick={e => adminPageHandler()} >
                    Admin
                </Button></Grid>}
            </Grid>
        );
    }

    function renderSeasons() {
        return (
            <Grid container spacing={2}>
                {organization!.orgSeasons.map(item =>
                    <Grid item key={item.id}>
                        <LinkTile firstName={item.name}
                            key={item.id}
                            linkUrl={'/orgseason/' + item.id}
                            imageUrl={item.logoLink} />
                    </Grid>
                )}
            </Grid>
        );
    }

    function renderNoSeasons() {
        return (
            <Box>
                <p>No seasons have been created for this organization</p>
            </Box>
        );
    }

    function mainContent() {
        return (
            <Box>
                {renderHeader()}
                <h3>Seasons</h3>
                {organization!.orgSeasons && organization!.orgSeasons.length > 0 && renderSeasons()}
                {(!organization!.orgSeasons || organization!.orgSeasons.length === 0) && renderNoSeasons()}
            </Box>
        );
    }

    return (
        <Box>
            <InfoBar navigateUrl={`/orgs`}  />
            <ErrorHeader errorMessage={errorMessage} />
           
            {getOrgApi.loading && <CircularProgress />}
            {organization && mainContent()}
        </Box>
    );

}