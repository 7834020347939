import { useEffect, useState } from 'react';
import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { ErrorHeader } from '../../shared/errorHeader';
import {
  AthleteEducation,
  AthleteExperience,
  AthleteInfo,
} from '../../api/models/athlete';
import { useParams } from 'react-router-dom';
import { getAthlete } from '../../api/athleteApi';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import { MiniGrid, RowItem } from '../../shared/miniGrid';
import { formatHeight } from '../../utils/dataFormatters';
import { generateQRCode } from '../../utils/qrCodeHelper';


dayjs.extend(duration);
dayjs.extend(utc);

export function AthletePage() {
  const [athlete, setAthlete] = useState<AthleteInfo | undefined>(undefined);
  const [qrCode, setQrCode] = useState<string | undefined>(undefined);
  const [experienceRows, setExperienceRows] = useState<RowItem[] | undefined>(
    undefined
  );
  const [educationRows, setEducationRows] = useState<RowItem[] | undefined>(
    undefined
  );
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [showExperience, setShowExperience] = useState<boolean>(false);
  const [showVideos, setShowVideos] = useState<boolean>(false);
  const [latestEducation, setLatestEducation] = useState<AthleteEducation | undefined>(undefined);

  const params = useParams();

  const birthDate = dayjs.utc(athlete?.birthDate);
  const age = dayjs().diff(birthDate, 'year');

  const schoolYear = dayjs.utc(latestEducation?.startDate);
  const nextYear = schoolYear.add(1, 'year');
  const hasYears = schoolYear.isValid() && nextYear.isValid();
  const displayYear = hasYears  ? `${schoolYear.format('YYYY')} - ${nextYear.format('YYYY')}` : '';

  useEffect(() => {
    initPage();
    generateQrCode();
  }, []);

  const initPage = async () => {
    try {
      setIsLoading(true);
      setErrorMessage('');

      if (!params.athleteGuid) {
        throw Error('Athlete GUID is missing');
      }

      await refreshMember(params.athleteGuid);
      setIsLoading(false);
    } catch (error: any) {
      if (error.response?.data) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage(error.message);
      }
      setIsLoading(false);
    }
  };

  async function generateQrCode() {
    const currentUrl = window.location.href;
    const qr = await generateQRCode(currentUrl);
    setQrCode(qr);
  }

  function mapExperienceToRows(responseExperience?: AthleteExperience[]) {
    if (responseExperience && responseExperience.length > 0) {
      const tempRows = responseExperience.map((exp) => ({
        id: exp.startDate.toString(),
        items: [
          { name: 'date', value: dayjs(exp.startDate).format('MM/YY') },
          { name: 'org', value: exp.orgName },
          { name: 'team', value: `${exp.seasonName} - ${exp.level}` },
        ],
      }));

      setExperienceRows(tempRows);
    }
  }

  function mapEducationToRows(responseEducation?: AthleteEducation[]) {
    if (responseEducation && responseEducation.length > 0) {
      const tempRows = responseEducation.map((education) => ({
        id: education.startDate.toString(),
        items: [
          { name: 'date', value: dayjs(education.startDate).format('MM/YY') },
          { name: 'school', value: education.schoolName },
          { name: 'grade level', value: `${education.schoolGrade}` },
          { name: 'gpa', value: `${education.gpa}` },
        ],
      }));

      setEducationRows(tempRows);
    }
  }

  async function refreshMember(athleteGuid: string) {
    const response = await getAthlete(athleteGuid);
    setAthlete(response);
    if (response.experiences) {
      mapExperienceToRows(response.experiences);
    }
    if (response.education) {
      const latestEducation = response.education.reduce((prev, current) => (prev.startDate > current.startDate ? prev : current));
      setLatestEducation(latestEducation);

      mapEducationToRows(response.education);
    }
  }

  const mainContent = () => {
    return (
      <Box>
        <br />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={athlete?.orgSeason?.logoLink} height={50} alt="profile" />
          {athlete?.orgSeason && (
            <h2 style={{ margin: '0 10px' }}>{athlete?.orgSeason.name}</h2>
          )}
        </div>

        <h1>
          {athlete?.firstName} {athlete?.lastName}{' '}
        </h1>
        {<img src={athlete?.profileLink} height={200} alt="profile" />}
        {athlete?.team && (
          <h3 style={{ margin: '0 10px' }}>
            {athlete?.team.name} - #{athlete?.jerseyNumber} - {athlete?.position}
          </h3>
        )}

        <br />

        <h2>Bio</h2>
        <Box sx={{ width: 300 }}>
        <TableContainer>
        <Table>
        <TableBody>
        <TableRow>
        <TableCell  size='small' sx={{ backgroundColor: '#0062A3', color: 'white', width:75 }}>Height:</TableCell>
        <TableCell  size='small' sx={{ backgroundColor: 'white', color: 'black' }}>
                <span>{formatHeight(athlete?.height)}</span>
                </TableCell>
              </TableRow>
            <TableRow>
            <TableCell  size='small'  sx={{ backgroundColor: '#0062A3', color: 'white' }}>Weight:</TableCell>
            <TableCell  size='small'>
                <span>{athlete?.weight}</span>
                </TableCell>
              </TableRow>
            <TableRow>
            <TableCell  size='small'  sx={{ backgroundColor: '#0062A3', color: 'white' }}>Birth Date:</TableCell>
            <TableCell  size='small'>
                <span>{birthDate.format('MM/DD/YY')}</span>
                </TableCell>
              </TableRow>
            <TableRow>
            <TableCell  size='small'  sx={{ backgroundColor: '#0062A3', color: 'white' }}>Age:</TableCell>
            <TableCell  size='small'>
                <span>{age}</span>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        </Box>

        <br/>
        <h2>Contact Info</h2>
        <Box sx={{ width: 400 }}>
        <TableContainer>
        <Table>
        <TableBody>
        <TableRow>
        <TableCell  size='small' sx={{ backgroundColor: '#0062A3', color: 'white', width:75 }}>Parents:</TableCell>
        <TableCell  size='small' >
                <span>{athlete?.pageInfo?.parentNames}</span>
        </TableCell>
        </TableRow>
        <TableRow>
            <TableCell size='small' sx={{ backgroundColor: '#0062A3', color: 'white' }}>Cell Phone:</TableCell>
            <TableCell size='small'>
                <span>{athlete?.pageInfo?.cellPhone}</span>
                </TableCell>
              </TableRow>
            <TableRow>
            <TableCell  size='small' sx={{ backgroundColor: '#0062A3', color: 'white',  verticalAlign: 'top' }}>Address:</TableCell>
            <TableCell  size='small'>
                <span>{athlete?.pageInfo?.addressLine1}<br/>{athlete?.pageInfo?.city},{athlete?.pageInfo?.state} {athlete?.pageInfo?.postalCode}</span>
                </TableCell>
              </TableRow>
           
            </TableBody>
          </Table>
        </TableContainer>
        </Box>

        {showExperience && (
          <>
            <h2>Experience</h2>
            {experienceRows && <MiniGrid rows={experienceRows} width={500} />}

            <br />
            
          </>
        )}
        <br />

        {showExperience && (
          <>
        <h2>Videos</h2>
        {athlete?.videos &&
          athlete?.videos.map((video) => (
            <div key={video.videoLink}>
              <a
                href={video.videoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={video.thumbnailLink}
                  alt={video.title}
                  style={{ width: '150px', height: 'auto' }}
                />
                <p>{video.title}</p>
              </a>
            </div>
          ))}

        <br />
        </>
        )}

        <br />

        <h2>Education</h2>
        {/*educationRows && <MiniGrid rows={educationRows} width={500} /> */}

        <Box sx={{ width: 400 }}>
        <TableContainer>
        <Table>
        <TableBody>
        <TableRow>
        <TableCell  size='small' sx={{ backgroundColor: '#0062A3', color: 'white', width:75 }}>Year</TableCell>
        <TableCell  size='small' >
                <span>{displayYear}</span>
        </TableCell>
        </TableRow>
        <TableRow>
            <TableCell size='small' sx={{ backgroundColor: '#0062A3', color: 'white' }}>School:</TableCell>
            <TableCell size='small'>
                <span>{latestEducation && (latestEducation.schoolName?.toString() ?? '')}</span>
                </TableCell>
              </TableRow>
            <TableRow>
            <TableCell  size='small' sx={{ backgroundColor: '#0062A3', color: 'white',  verticalAlign: 'top' }}>GradeLevel:</TableCell>
            <TableCell  size='small'>
                <span>{latestEducation && (latestEducation.schoolGrade?.toString() ?? '')}</span>
                </TableCell>
              </TableRow>

              <TableRow>
            <TableCell  size='small' sx={{ backgroundColor: '#0062A3', color: 'white',  verticalAlign: 'top' }}>GPA:</TableCell>
            <TableCell  size='small'>
                <span>{latestEducation && (latestEducation.gpa?.toString() ?? '')}</span>
                </TableCell>
              </TableRow>
           
            </TableBody>
          </Table>
        </TableContainer>
        </Box>


        <p>Transcripts available upon request</p>

        <br />
        <br />

        {/* {qrCode && <img src={qrCode} height={200} alt="profile" />} */}

        <br />
      </Box>
    );
  };

  return (
    <Box>
      <ErrorHeader errorMessage={errorMessage} />
      {isLoading && <CircularProgress />}
      {athlete && mainContent()}
    </Box>
  );
}
