import { Box, Button, Drawer, Grid, IconButton, Link } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';

export interface BasicMenuItem{
    title:string,
    onClick:() =>void
}

export interface BasicMenuProps {
    items:BasicMenuItem[]
}

export function BasicMenu(props:BasicMenuProps) {

    const [open, setState] = useState(false);


    const toggleDrawer = (open: any) => (event: any) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState(open);
    };

    return (
        <>
            <Box sx={{ mr: '10px', display: { xs: 'block' } }}>

                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer(true)}
                    sx={{ mr: 2, display: { xs: 'block' } }}>
                    <MenuIcon fontSize="large" />
                </IconButton>

                <Drawer
                    anchor="right"
                    variant="temporary"
                    open={open}
                    onClose={toggleDrawer(false)}
                >

                    <Box sx={{ width: '300px', padding: '10px' }}>
                        <Grid container direction='column' spacing={2}>
                            {props.items.map(x=><Grid item> 
                            <Button color="primary"
                                    variant="contained"
                                    onClick={x.onClick} >
                                    {x.title}
                                </Button></Grid>)}
                        </Grid>
                    </Box>
                </Drawer>

            </Box>
        </>

    );
}