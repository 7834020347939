import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from '@mui/material';
import { getOrganizations } from '../../api/organizationApi';
import { LinkTile } from "../../shared/linkTile";
import { Organization } from "../../api/models/organization";
import { ErrorHeader } from "../../shared/errorHeader";
import { useApi } from "../../hooks/useApi";
import { LoginHeader } from "../../shared/loginHeader";
import { InfoBar } from "../../shared/infoBar";

export function OrganizationListPage() {
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const getOrgsApi = useApi(getOrganizations);

    const publicOrgs = organizations.filter(x => x.isPublic);
    const privateOrgs = organizations.filter(x => !x.isPublic);

    useEffect(() => {
        initPage();
    }, []);

    useEffect(() => {
        if (getOrgsApi.data) {
            console.log(`org data`,getOrgsApi.data);
            setOrganizations(getOrgsApi.data);
        }
    }, [getOrgsApi.data]);

    useEffect(() => {
        if (getOrgsApi.error) {
            setErrorMessage(getOrgsApi.error);
        }
    }, [getOrgsApi.error]);

    function initPage(){
        getOrgsApi.request();
    }

    const mainContent = () => {

        return (
            <Box>
                 <InfoBar  />
                <h1>Organizations</h1>
                <Grid container spacing={2}>
                    {publicOrgs.map(item =>
                        <Grid item key={item.id}>
                            <LinkTile firstName={item.name}
                                key={item.id}
                                linkUrl={'/orgs/' + item.id}
                                imageUrl={item.logoLink} />
                        </Grid>
                    )}
                </Grid>
                {privateOrgs && privateOrgs.length > 0 &&
                    <Box>
                <h3>Private Organizations</h3>
                <Grid container spacing={2}>
                    {privateOrgs.map(item =>
                        <Grid item key={item.id}>
                            <LinkTile firstName={item.name}
                                key={item.id}
                                linkUrl={'/orgs/' + item.id}
                                imageUrl={item.logoLink} />
                        </Grid>
                    )}
                </Grid>
                </Box>
            }
            </Box>
        );
    }

    return (

        <Box>
            <ErrorHeader errorMessage={errorMessage} />
            {getOrgsApi.loading && <CircularProgress />}
            {organizations && mainContent()}
            {!organizations && <p>No organizations found</p>}
        </Box>
    );

}